@import-normalize;

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #334d6e;
}
html {
  font-size: 14px;
}
.MuiDataGrid-columnHeaderTitle {
  white-space: normal !important;
  line-height: 1.2;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #2f80ed;
}

a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
